<template>
<div id="ConsolePanel">
    <el-row class="panel-header" ref="panelHeader">
        <el-col :span="12" style="font-weight: bold">
            <p>
                <span style="color: rgba(240, 90, 35, 1); font-size: 1.5em"></span>
                <span style="color: #409eff; font-size: 1.5em">访客预约</span>
                <span style="color: rgba(240, 90, 35, 1); font-size: 1.5em">管理系统</span>
            </p>
            <p class="t"></p>
        </el-col>
        <el-col :span="12" style="text-align: right; line-height: 50px">
            <el-dropdown trigger="click" @command="dropdownCommand">
                <span v-if="accountInfo.passportId" class="el-dropdown-link">
                    <i class="iconfont icon-usercenter" />{{accountInfo.passportId}}
                    <el-icon class="el-icon--right">
                        <arrow-down />
                    </el-icon>
                </span>
                <span v-if="!accountInfo.passportId">
                    账户信息加载中......
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="passwordChange" divided><i class="el-icon-right" />修改密码</el-dropdown-item>
                        <el-dropdown-item command="exit" divided><i class="el-icon-right" />安全退出</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </el-col>
    </el-row>
    <div class="worker-area">
        <div :style="'min-width: 200px; height: ' + workerHeight + 'px'">
            <NavigationMenu :viewId="viewId" :navigationMenus="navigationMenus" @menuItemIndex="menuItemSelected($event)"></NavigationMenu>
        </div>
        <div :style="'width: ' + workerRightWidth + 'px; padding: 15px; background: white'">
            <el-tabs v-model="viewId" type="card" @tab-click="switchViewContent" @tab-remove="removeViewContent" @contextmenu.prevent="openContextMenu($event)">
                <el-tab-pane v-for="item in viewBox" :label="item.title" :key="item.name" :name="item.name" :closable="item.name == 'StatisticsIndex' ? false : true">
                    <template #label>
                        <i :class="item.icon"></i>
                        <span>{{item.title}}</span>
                    </template>
                    <keep-alive>
                        <component @menuItemIndex="menuItemSelected($event)" @viewUnionId="viewUnionId($event)" :is="viewId" :viewId="item.name" :unionId="unionId" :terminalToken="terminalToken"></component>
                    </keep-alive>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
    <ul v-show="contextMenuVisible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
        <li @click="closeAllTabs">关闭所有</li>
        <li @click="closeOtherTabs('closedLeftTabs')">关闭左边</li>
        <li @click="closeOtherTabs('closedRightTabs')">关闭右边</li>
        <li @click="closeOtherTabs('closedOtherTabs')">关闭其他</li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'ConsolePanel',
    data() {
        return {
            accountInfo: {},
            terminalToken: this.$store.state.terminalToken,
            contextMenuVisible: false,
            currentContextTabId: '',
            left: 0,
            top: 0,
            workerHeight: document.documentElement.clientHeight - 70,
            workerRightWidth: document.documentElement.clientWidth - 230,
            unionId: '',
            viewBox: [{
                title: '概况',
                icon: 'iconfont icon-topsales',
                name: 'StatisticsIndex',
            }, ],
            viewId: 'StatisticsIndex',
            navigationMenus: [{
                uid: 'StatisticsIndex',
                icon: 'iconfont icon-topsales',
                title: '概况',
            }, {
                uid: 'Visitor',
                icon: 'iconfont icon-usercenter',
                title: '访客管理',
                subMenu: [{
                    index: 'VisitorOrderLists',
                    title: '预约申请单',
                    icon: 'iconfont icon-order',
                    show: true,
                }, {
                    index: 'VisitorOrderDetail',
                    title: '预约申请单详情',
                    icon: 'iconfont icon-order',
                    show: false,
                }],
            }, {
                uid: 'System',
                icon: 'iconfont icon-set',
                title: '系统管理',
                subMenu: [{
                    index: 'PasswordChange',
                    title: '密码修改',
                    icon: 'iconfont icon-password',
                    show: true,
                }, {
                    index: 'UserLists',
                    title: '用户管理',
                    icon: 'iconfont icon-usercenter',
                    show: true,
                }, {
                    index: 'UserAdd',
                    title: '用户新增',
                    icon: 'iconfont icon-usercenter',
                    show: false,
                }, {
                    index: 'UserEdit',
                    title: '用户编辑',
                    icon: 'iconfont icon-usercenter',
                    show: false,
                }],
            }, ],
        }
    },
    created() {
        let data = {}
        let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
        this.$http.post('/passport/manage/info', data, {
            'terminal-token': this.terminalToken,
            'terminal-signature': terminalSignature
        }, (response) => {
            let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
            if (signatureVerify) {
                this.accountInfo = response.data.data
            } else {
                this.$toast('验证签名失败')
            }
        });
        let viewBox = localStorage.getItem('viewBox')
        let viewId = localStorage.getItem('viewId')
        if (viewBox) {
            this.viewBox = JSON.parse(viewBox)
            this.viewId = viewId ? viewId : this.viewId
        } else {
            this.navigationMenus.forEach((item) => {
                if (item.subMenu) {
                    item.subMenu.forEach((subItem) => {
                        if (subItem.index == this.viewId) {
                            this.viewBox.push({
                                title: subItem.title,
                                icon: subItem.icon,
                                name: this.viewId
                            })
                        }
                    })
                }
            })
        }
    },
    watch: {
        contextMenuVisible() {
            if (this.contextMenuVisible) {
                document.body.addEventListener('click', this.closeContextMenu)
            } else {
                document.body.removeEventListener('click', this.closeContextMenu)
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.getWorkerArea)
        this.getWorkerArea()
    },
    methods: {
        dropdownCommand(command) {
            switch (command) {
                case 'passwordChange':
                    console.log('修改密码');
                    this.menuItemSelected('PasswordChange')
                    break;
                case 'exit':
                    this.$store.commit('updateTerminalToken', null)
                    this.$router.replace('/')
                    break;
                default:
                    break;
            }
        },
        getWorkerArea() {
            this.workerHeight = document.documentElement.clientHeight - 70
            this.workerRightWidth = document.documentElement.clientWidth - 230
        },
        menuItemSelected(viewId) {
            let allowAdd = true
            this.viewBox.forEach((item, index) => {
                if (index > 0 && item.name == viewId) {
                    allowAdd = false
                }
            })
            this.viewId = viewId
            localStorage.setItem('viewId', this.viewId)
            if (allowAdd) {
                this.navigationMenus.forEach((item) => {
                    if (item.subMenu) {
                        item.subMenu.forEach((subItem) => {
                            if (subItem.index == this.viewId) {
                                this.viewBox.push({
                                    title: subItem.title,
                                    icon: subItem.icon,
                                    name: this.viewId,
                                })
                            }
                        })
                    }
                })
                localStorage.setItem('viewBox', JSON.stringify(this.viewBox))
            }
        },
        viewUnionId(unionId) {
            this.unionId = unionId
        },
        switchViewContent() {
            localStorage.setItem('viewId', this.viewId)
        },
        removeViewContent(targetName) {
            let tabs = this.viewBox
            let activeName = this.viewId
            if (activeName === targetName) {
                tabs.forEach((tab, index) => {
                    if (tab.name === targetName) {
                        let nextTab = tabs[index + 1] || tabs[index - 1]
                        if (nextTab) {
                            activeName = nextTab.name
                        }
                    }
                })
            }
            this.viewId = activeName
            this.viewBox = tabs.filter((tab) => tab.name !== targetName)
            localStorage.setItem('viewBox', JSON.stringify(this.viewBox))
            localStorage.setItem('viewId', activeName)
        },
        openContextMenu(e) {
            let elementId = e.srcElement.parentNode.id
            if (elementId) {
                let currentContextTabId = elementId.split('-')[1]
                this.currentContextTabId = currentContextTabId
                this.contextMenuVisible = true
                this.left = e.clientX
                this.top = e.clientY + 10
            }
        },
        closeContextMenu() {
            this.contextMenuVisible = false
        },
        closeAllTabs() {
            this.viewBox.forEach((item, index) => {
                if (index > 0) {
                    this.removeViewContent(item.name)
                }
            })
            this.contextMenuVisible = false
        },
        closeLeftTabs() {
            let selectedTabsIndex = 0
            this.viewBox.forEach((item, index) => {
                if (index > 0) {
                    if (item.name == this.currentContextTabId) {
                        this.viewID = this.currentContextTabId
                        localStorage.setItem('viewID', this.currentContextTabId)
                        selectedTabsIndex = index
                    }
                }
            })
            for (let i = 1; i < selectedTabsIndex; i++) {
                this.viewBox.splice(selectedTabsIndex - i, 1)
            }
        },
        closeRightTabs() {
            let selectedTabsIndex = 0
            let deleteTabsNumber = 0
            this.viewBox.forEach((item, index) => {
                if (index > 0) {
                    if (item.name == this.currentContextTabId) {
                        this.viewID = this.currentContextTabId
                        localStorage.setItem('viewID', this.currentContextTabId)
                        selectedTabsIndex = index
                    } else {
                        deleteTabsNumber++
                    }
                }
            })
            this.viewBox.splice(selectedTabsIndex + 1, deleteTabsNumber)
        },
        closeOtherTabs(command) {
            if (command == 'closedLeftTabs') {
                this.closeLeftTabs()
                localStorage.setItem('viewBox', JSON.stringify(this.viewBox))
            }
            if (command == 'closedRightTabs') {
                this.closeRightTabs()
                localStorage.setItem('viewBox', JSON.stringify(this.viewBox))
            }
            if (command == 'closedOtherTabs') {
                this.closeLeftTabs()
                this.closeRightTabs()
                localStorage.setItem('viewBox', JSON.stringify(this.viewBox))
            }
            this.contextMenuVisible = false
        },
    },
}
</script>

<style lang="less" scoped>
.panel-header {
    height: 70px;
    line-height: 1.5em;
    background: #f4f4f4;
    padding: 15px 20px;
    font-family: 'Courier New', Courier, monospace;
}

.t {
    color: #cccccc;
    font-size: 12px;
    text-indent: 5em;
}

.el-dropdown-link {
    cursor: pointer;
}

.worker-area {
    display: -webkit-flex;
    display: flex;
}

.contextmenu {
    width: 100px;
    margin: 0;
    border: 1px solid #ccc;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.contextmenu li {
    margin: 0;
    padding: 7px 16px;
}

.contextmenu li:hover {
    background: #f2f2f2;
    cursor: pointer;
}
</style>
